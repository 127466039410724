/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
@import "../vars.css";

.sidebar_theme_alfa-on-white {
    color: var(--color-content-alfa-on-white);
    background-color: var(--color-background-alfa-on-white);
}

@media (--small-only) {
    .sidebar_theme_alfa-on-white {
        .sidebar__header {
            /* TODO заменить на токены, цвета должны соответствовать Modal */
            background: var(--color-white-95-deprecated);
            border-bottom: 1px solid var(--color-black-10);
        }

        .sidebar__footer:after {
            background-image: var(--color-gradient-edge-screen-alfa-on-white);
        }
    }
}
